import { Component, OnInit } from '@angular/core';
import { LoginInfoComponent } from "../../user/login-info/login-info.component";


@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html',
//   styles: [`
//   .nav-ul{
//     width :220px;
//     height :500px;
//     overflow :hidden;
//     overflow-y:scroll;
//   }
// `]

// nav ul {
//   width: 220px;
//   padding: 40px 28px 25px 0;
//   padding: 0;
//   margin: 0;
//   font-size: 13px;
//   line-height: .5em;
//   list-style: none;
//   position: relative;
//   height: 600px;
//   overflow: hidden;
//   overflow-y: scroll;
//}
})
export class NavigationComponent implements OnInit {

  constructor() {
  }
  userType: any;
  ngOnInit() {
    this.userType = sessionStorage.getItem('userRole')
  }

}
