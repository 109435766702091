import { ReportsModuleComponent } from "app/reports-module/reports-module.component";

export const endponitConfig: any = {
    USER_LOGIN: 'https://apis.medicalbulkbuy.com/login',
    USER_API_ENDPOINT: 'https://apis.medicalbulkbuy.com/api/users/',
    FACILITY_ENDPOINT: 'https://apis.medicalbulkbuy.com/mbb/facility/',
    INVENTORY_ENDPOINT: 'https://apis.medicalbulkbuy.com/mbb/inventory/',
    INVENTORY_ITEM_ENDPOINT: 'https://apis.medicalbulkbuy.com/mbb/inventoryItem/',
    MBB_INVENTORY: 'https://apis.medicalbulkbuy.com/mbb/',
    DISPATCH_ENDPONT: 'https://apis.medicalbulkbuy.com/mbb/dispatch/',
    BARCODES_ENDPOINT: 'https://apis.medicalbulkbuy.com/mbb/barcodes/',
    INVOICEDETAILS_ENDPOINT: 'https://apis.medicalbulkbuy.com/mbb/returnDetails/',
    CHECKLIST_ENDPONT: 'https://apis.medicalbulkbuy.com/mbb/checklist/',
    RETURN_ENDPOINT: 'https://apis.medicalbulkbuy.com/mbb/returns/',
    MBB_ORDERS: 'https://apis.medicalbulkbuy.com/mbb/orderDetails/',
    MBB_SHIPMENTS: 'https://apis.medicalbulkbuy.com/mbb/zepoSRShipments/',
    MBB_REMITTANCE: 'https://apis.medicalbulkbuy.com/mbb/codremittance/',
    VENDORS_API_ENDPOINT: 'https://apis.medicalbulkbuy.com/mbb/poVendor/',
    VENDORS_API: 'https://apis.medicalbulkbuy.com/mbb/vendor/',
    VENDOR_ITEM_DETAILS: 'https://apis.medicalbulkbuy.com/mbb/vendorItemDetails/',
    INVOICE_DETAILS: 'https://apis.medicalbulkbuy.com/mbb/invoiceDetails/',
    ALL_SHIPMENTS_COD: 'https://apis.medicalbulkbuy.com/mbb/',
    ALL_REM_COD: 'https://apis.medicalbulkbuy.com/mbb/',
    ALL_REM_BYPAY_COD: 'https://apis.medicalbulkbuy.com/api/',
    BANK_DETAILS: 'https://apis.medicalbulkbuy.com/mbb/bankDetails/',
    PRICE_DETAILS: 'https://apis.medicalbulkbuy.com/mbb/priceDetails/',
    OTHER_CHARGES: 'https://apis.medicalbulkbuy.com/mbb/otherCharges/',
    VENDORS_API_ENDPOINT_NEW: 'https://apis.medicalbulkbuy.com/mbb/vendor/',
    INVENTORY_TRANSFER: 'https://apis.medicalbulkbuy.com/mbb/transferInventory/',
    PACKAGE_DETAILS: 'https://apis.medicalbulkbuy.com/mbb/packageDetails/',
    TRANSPORT_DETAILS: 'https://apis.medicalbulkbuy.com/mbb/transportation/',
    SELF_TRANSPORT: 'https://apis.medicalbulkbuy.com/mbb/selfTransport/',
    AGGGTR_TRANSPORT: 'https://apis.medicalbulkbuy.com/mbb/shippingAggregator/',
    DOCUMENTS_UPLOAD: 'https://apis.medicalbulkbuy.com/mbb/transferDocument/',
    FAST_MOVING_SKU: 'https://apis.medicalbulkbuy.com/mbb/fastMovingSku/',
    RETURNS_API: 'https://apis.medicalbulkbuy.com/mbb/dispatch/',
    RETURNS_DETAILS_API: 'https://apis.medicalbulkbuy.com/mbb/',
    SERVICE_PRODUCTS_API: 'https://apis.medicalbulkbuy.com/mbb/servicing/',
    SERVICING_API: 'https://apis.medicalbulkbuy.com/mbb/quotation/',
    SPAREPARTS_API: 'https://apis.medicalbulkbuy.com/mbb/spareparts/',
    SERVICING_CHECKLIST_API: 'https://apis.medicalbulkbuy.com/mbb/checklist/',
    RENTALS_API: 'https://apis.medicalbulkbuy.com/mbb/rentals/',
    DEMO_PRODUCTS_API: 'https://apis.medicalbulkbuy.com/mbb/demo/',
    REPORTS_HISTORY_API: 'https://apis.medicalbulkbuy.com/mbb/reportsModule/'
};
